import VueRouter from "vue-router";
import NProgress from "nprogress"; // 路由跳转时，出现在页面顶部的进度条。

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/admin/login",
            name: "login",
            component: () => import("@/pages/login")
        },
        {
            path: "/admin",
            name: "admin_home",
            component: () => import("@/pages/layout/index"), // 登录后的页面 Layout.
            redirect: { name: "book_list" },
            children: [
                {
                    path: "books",
                    name: "book_list",
                    component: () => import("@/pages/books/index")
                },
                {
                    path: "editbook/:id",
                    name: "edit_book",
                    props: true,
                    component: () => import("@/pages/books/add-or-edit")
                },
                {
                    path: "add_book",
                    name: "add_book",
                    component: () => import("@/pages/books/add-or-edit")
                },
                {
                    path:"inventories",
                    name:'inventories',
                    component:()=>import('@/pages/inventory/index')
                },
                {
                    path: "edit_inventory/:id",
                    name: "edit_inventory",
                    props: true,
                    component: () => import("@/pages/inventory/add-or-edit")
                },
                {
                    path: "add_inventory",
                    name: "add_inventory",
                    component: () => import("@/pages/inventory/add-or-edit")
                },
                {
                    path:"customers",
                    name:"customers",
                    component:()=>import('@/pages/customers/index')
                },
                {
                    path:"orders",
                    name:"orders",
                    component:()=>import('@/pages/orders/index')
                },
                {
                    path:"type",
                    name:"type",
                    component:()=>import('@/pages/type/index')
                },
                {
                    path:"pic",
                    name:"pic",
                    component:()=>import('@/pages/image/index')
                },
                {
                    path: "projects",
                    name: "projects",
                    component: () => import("@/pages/admin/projects/index") // 项目列表页面
                },
                {
                    path: "projectlist/:type",
                    name: "projectlist",
                    component: () => import("@/pages/admin/projects/index") // 项目列表页面
                },
                {
                    path: "projectdetails/:id",
                    name: "project_details",
                    props: true,
                    component: () => import("@/pages/admin/projects/details/index") // 项目详情页面
                },
                {
                    path: "users",
                    name: "users",
                    component: () => import("@/pages/admin/users/index") // 用户列表页面
                },
                {
                    path: "edituser/:id",
                    name: "edit_user",
                    props: true,
                    component: () => import("@/pages/admin/users/add-or-edit") // 用户编辑页面。
                },
                {
                    path: "userhome",
                    name: "user_home",
                    component: () => import("@/pages/admin/userhome/index") // 用户个人中心页面。
                },

                {
                    path: "usergroups",
                    name: "usergroups",
                    component: () => import("@/pages/admin/usergroups/index") // 用户组列表页面
                },
                {
                    path: "addgroup",
                    name: "add_group", // 用户列表页面
                    component: () => import("@/pages/admin/usergroups/add-or-edit.vue") // 添加用户组页面
                },
                {
                    path: "editgroup/:id",
                    name: "edit_group",
                    props: true,
                    component: () => import("@/pages/admin/usergroups/add-or-edit.vue") // 修改用户组页面
                },

                {
                    path: "menus",
                    name: "menus",
                    component: () => import("@/pages/admin/menus/index") // 菜单列表页面
                },
                {
                    path: "help",
                    name: "help",
                    component: () => import("@/pages/admin/help/index") // 帮助中心页面
                },
                {
                    path: "addmenu",
                    name: "add_menu",
                    component: () => import("@/pages/admin/menus/add-or-edit") // 添加菜单项页面
                },
                {
                    path: "editmenu/:id",
                    name: "edit_menu",
                    props: true,
                    component: () => import("@/pages/admin/menus/add-or-edit") // 修改菜单项页面
                },

                {
                    path: "workflows",
                    name: "workflows",
                    component: () => import("@/pages/admin/workflows/index") // 工作流列表页面
                },
                {
                    path: "addworkflow",
                    name: "add_workflow",
                    component: () => import("@/pages/admin/workflows/add-or-edit") // 添加工作流页面
                },
                {
                    path: "editworkflow/:id",
                    name: "edit_workflow",
                    props: true,
                    component: () => import("@/pages/admin/workflows/add-or-edit") // 修改工作流页面
                },
                {
                    path: "tickets",
                    name: "tickets",
                    component: () => import("@/pages/admin/tickets/index") // 工作流列表页面
                },
                {
                    path: "equipment",
                    name: "equipment",
                    component: () => import("@/pages/admin/equipment/index") // 设备管理页面
                },
                {
                    path: "testitems",
                    name: "testitems",
                    component: () => import("@/pages/admin/testitems/index") // 指标管理页面
                },
            ]
        },
        {
            path: "/invalid",
            name: "invalid",
            component: () => import("@/pages/invalid/invalid")
        },
        {
            path: "/admin/support",
            name: "support",
            component: () => import("@/pages/support/Support")
        },
        {
            path: "/admin/privacy",
            name: "privacy",
            component: () => import("@/pages/support/Privacy")
        },
        {
            path: "*",
            name:"*",
            redirect:"/invalid",
        }
    ]
});

// 路由开始跳转时，加载进度条。路由跳转完成时，隐藏进度条。
router.beforeEach((to, from, next) => {
    NProgress.start();
    if (['/admin/login'].includes(to.path)){
        return next();
    }
    // 获取token
    if (to.path.indexOf("support") !== -1 ){
        return next();
    }
    if (to.path.indexOf("privacy") !== -1 ){
        return next();
    }
    const tokenStr = window.localStorage.getItem('token');
    if (!tokenStr){
        return next('/admin/login');
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});
export default router;
